import React from "react";
import { Home, Rocket, ThumbsUp, Droplet } from "lucide-react";
import { motion } from "framer-motion";

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className="flex items-center mb-4">
      <div className="text-emerald-500 mr-4">{icon}</div>
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const Features = () => {
  const features = [
    {
      icon: <Home size={24} />,
      title: "막힘없는 분쇄력",
      description: "강력한 분쇄 시스템으로 어떤 음식물도 문제없이 처리합니다.",
    },
    {
      icon: <Rocket size={24} />,
      title: "경제적인 유지관리",
      description: "효율적인 설계로 장기적인 비용 절감 효과를 제공합니다.",
    },
    {
      icon: <ThumbsUp size={24} />,
      title: "편리하고 안전한 사용",
      description:
        "직관적인 인터페이스와 다중 안전장치로 누구나 쉽게 사용할 수 있습니다.",
    },
    {
      icon: <Droplet size={24} />,
      title: "친환경적이고 위생적인 처리",
      description: "UV 살균 기술로 청결하고 위생적인 주방 환경을 만듭니다.",
    },
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold text-center mb-10 text-gray-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          다이손의 특징
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
