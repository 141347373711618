import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-2xl font-bold mb-4">DAISON</h3>
            <p>
              회사명: 다이손
              <br />
              대표: 문종기
              <br />
              사업자등록: 142-81-58519
              <br />
              본사/공장: 경기도 안산시 단원구 원시오동 521
            </p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-xl  mb-4">인증</h4>
            {/* Add certification logos here */}
          </div>
          <div className="w-full md:w-1/3">
            <h4 className="text-xl  mb-4">고객 지원</h4>
            <p>전화: 1666-8543</p>
            <p>이메일: support@daison.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
