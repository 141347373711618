import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  BeakerIcon,
  ShieldCheckIcon,
  SparklesIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";

const ProductPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("일반");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const productCategories = {
    일반: [
      {
        id: "DS-77D",
        name: "DS-77D",
        image: "/images/ds-77d.jpg",
        specs: {
          power: "200V/375W",
          rpm: "3800rpm",
          weight: "4.12kg",
          size: "360x350mm",
          frequency: "60hz",
          motorType: "연구자석모터",
          grindingType: "칼날햄머방식",
          drainageType: "연속 투입방식",
          waterproofGrade: "방수방식",
        },
        features: [
          "원터치로 해결되는 편리함",
          "약 1분이내로 음식물 쓰레기 실시간 처리방식",
          "깔끔함, 위생",
          "냄새, 세균 걱정 없이 주방이 깨끗하다",
          "소음절감과 탁월한 성능",
          "영구자석 모터를 사용해 반영구적 고장없이 사용가능",
          "친환경적 안전한 기술력",
          "자동장 칼날방식과 과부하 방지를 위한 리셋 벙 사용으로 안전함",
        ],
      },
      {
        id: "DS-88D",
        name: "DS-88D",
        image: "/images/DS-88D.jpg",
        specs: { power: "600W", rpm: "4000rpm", weight: "5.6kg" },
      },
    ],
    고급: [
      {
        id: "DS-100D",
        name: "DS-100D Premium",
        image: "/images/ds-100d1.jpg",
        specs: { power: "750W", rpm: "4200rpm", weight: "6.2kg" },
      },
      {
        id: "DS-110X",
        name: "DS-110X Deluxe",
        image: "/images/DS-110X.jpg",
        specs: { power: "800W", rpm: "4500rpm", weight: "6.5kg" },
      },
    ],
    미생물: [],
  };

  const usageSteps = [
    { id: 1, text: "물을 틀다", icon: "💧" },
    { id: 2, text: "스위치 ON", icon: "🔌" },
    { id: 3, text: "음식물 투입", icon: "🥕" },
    { id: 4, text: "처리 완료", icon: "✅" },
    { id: 5, text: "물 흘려주기", icon: "🚰" },
  ];

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const ProductDetailModal = ({ product, isOpen, closeModal }) => (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                {product ? (
                  <>
                    <div className="sticky top-0 bg-white z-10 px-6 py-4 border-b">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                      >
                        {product.name}
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={closeModal}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </Dialog.Title>
                    </div>
                    <div className="max-h-[70vh] overflow-y-auto px-6 py-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <img
                            src={product.image}
                            alt={product.name}
                            className="w-full h-auto object-cover rounded-lg mb-4"
                          />
                          <h4 className="text-lg font-semibold mb-2">
                            제품 특징
                          </h4>
                          <ul className="list-disc list-inside text-sm text-gray-600">
                            {product.features?.map((feature, index) => (
                              <li key={index}>{feature}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h4 className="text-lg font-semibold mb-2">
                            제품 정보
                          </h4>
                          <table className="w-full text-sm text-gray-600">
                            <tbody>
                              {Object.entries(product.specs || {}).map(
                                ([key, value]) => (
                                  <tr key={key} className="border-b">
                                    <td className="py-2 font-medium">{key}</td>
                                    <td className="py-2">{value}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="mt-6">
                        <h4 className="text-lg font-semibold mb-2">
                          설치 사진
                        </h4>
                        <div className="grid grid-cols-2 gap-4">
                          <img
                            src="/images/installation1.jpg"
                            alt="설치 사진 1"
                            className="w-full h-auto rounded-lg"
                          />
                          <img
                            src="/images/installation2.jpg"
                            alt="설치 사진 2"
                            className="w-full h-auto rounded-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="p-6">제품 정보를 불러오는 중입니다...</p>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  const MicrobialSection = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-2xl font-semibold mb-6 text-center">
        다이손 미생물 기술
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex items-start space-x-4">
          <BeakerIcon className="h-8 w-8 text-emerald-500 flex-shrink-0" />
          <div>
            <h4 className="text-lg font-semibold mb-2">고효율 미생물 분해</h4>
            <p>
              특수 배양된 미생물이 음식물 쓰레기를 효과적으로 분해하여 악취와
              오염을 줄입니다.
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-4">
          <ShieldCheckIcon className="h-8 w-8 text-emerald-500 flex-shrink-0" />
          <div>
            <h4 className="text-lg font-semibold mb-2">환경 친화적</h4>
            <p>
              화학 물질 대신 자연 미생물을 사용하여 환경 부담을 최소화합니다.
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-4">
          <SparklesIcon className="h-8 w-8 text-emerald-500 flex-shrink-0" />
          <div>
            <h4 className="text-lg font-semibold mb-2">위생적인 주방 환경</h4>
            <p>유해 세균의 번식을 억제하여 주방을 위생적으로 유지합니다.</p>
          </div>
        </div>
        <div className="flex items-start space-x-4">
          <ChartBarIcon className="h-8 w-8 text-emerald-500 flex-shrink-0" />
          <div>
            <h4 className="text-lg font-semibold mb-2">지속적인 효과</h4>
            <p>지속적으로 작용하여 배수관 막힘을 예방하고 악취를 제거합니다.</p>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h4 className="text-lg font-semibold mb-4">미생물 작용 과정</h4>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <img
            src="/images/micro1.jpg"
            alt="미생물 이미지 1"
            className="w-full h-auto rounded"
          />
          <img
            src="/images/micro2.jpg"
            alt="미생물 이미지 2"
            className="w-full h-auto rounded"
          />
          <img
            src="/images/micro3.jpg"
            alt="미생물 이미지 3"
            className="w-full h-auto rounded"
          />
          <img
            src="/images/micro4.jpg"
            alt="미생물 이미지 4"
            className="w-full h-auto rounded"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>다이손 - 제품 라인업 | 친환경 음식물 분쇄처리기</title>
        <meta
          name="description"
          content="다이손의 다양한 음식물 분쇄처리기 제품 라인업을 살펴보세요. 일반, 고급 제품과 미생물 처리 기술에 대해 알아보세요."
        />
        <link rel="canonical" href="https://www.daison.com/products" />
      </Helmet>
      <div className="container mx-auto px-4 py-12">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          다이손 제품 라인업
        </motion.h1>

        <Tab.Group
          onChange={(index) =>
            setSelectedCategory(Object.keys(productCategories)[index])
          }
        >
          <Tab.List className="flex space-x-1 rounded-xl bg-emerald-900/20 p-1 mb-8">
            {Object.keys(productCategories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-emerald-700
                  ${
                    selected
                      ? "bg-white shadow"
                      : "text-emerald-100 hover:bg-white/[0.12] hover:text-white"
                  }`
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {Object.keys(productCategories).map((category) => (
              <Tab.Panel key={category} className="rounded-xl bg-white p-3">
                {category === "미생물" ? (
                  <MicrobialSection />
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {productCategories[category].map((product) => (
                      <motion.div
                        key={product.id}
                        className="bg-white rounded-lg shadow-md overflow-hidden"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <img
                          src={product.image}
                          alt={product.name}
                          className="w-full h-48 object-cover"
                        />
                        <div className="p-4">
                          <h3 className="text-lg font-semibold mb-2">
                            {product.name}
                          </h3>
                          <button
                            className="bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-600 transition duration-300"
                            onClick={() => openModal(product)}
                          >
                            자세히 보기
                          </button>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>

        <motion.div
          className="mt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold mb-8 text-center">사용 방법</h2>
          <div className="relative">
            <div className="absolute top-[15%] sm:top-[25%] md:top-[25%] left-6 right-6 h-1 bg-emerald-500 transform -translate-y-1/2"></div>
            <div className="flex justify-between relative z-10">
              {usageSteps.map((step, index) => (
                <div
                  key={step.id}
                  className={`flex flex-col items-center ${
                    index === 0
                      ? "pl-0"
                      : index === usageSteps.length - 1
                      ? "pr-0"
                      : ""
                  }`}
                >
                  <div
                    className={`w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full bg-emerald-500 flex items-center justify-center text-white text-base sm:text-xl md:text-2xl mb-2 ${
                      index === usageSteps.length - 1 ? "bg-emerald-700" : ""
                    }`}
                  >
                    {step.icon}
                  </div>
                  <div className="text-center w-16 sm:w-20 md:w-24">
                    <div className="font-semibold text-xs sm:text-sm md:text-base">
                      {step.id}단계
                    </div>
                    <div className="text-xs sm:text-sm">{step.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>

        <ProductDetailModal
          product={selectedProduct}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      </div>
    </>
  );
};

export default ProductPage;
