import React from "react";
import { Settings, ShoppingCart, Phone } from "lucide-react";
import { motion } from "framer-motion";

const ServiceCard = ({ icon, title, description, details, ctaText }) => (
  <motion.div
    className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300 border border-emerald-100"
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className="flex items-center mb-6">
      <div className="bg-emerald-100 p-3 rounded-full mr-4">
        {React.cloneElement(icon, { size: 24, className: "text-emerald-600" })}
      </div>
      <h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
    </div>
    <p className="text-gray-600 mb-6">{description}</p>
    <ul className="space-y-2 mb-6">
      {details.map((detail, index) => (
        <li key={index} className="flex items-start">
          <span className="text-emerald-500 mr-2">•</span>
          <span className="text-gray-700">{detail}</span>
        </li>
      ))}
    </ul>
    <motion.button
      className="w-full bg-emerald-500 text-white py-2 px-4 rounded-md hover:bg-emerald-600 transition duration-300"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {ctaText}
    </motion.button>
  </motion.div>
);

const Services = () => {
  const services = [
    {
      icon: <Settings />,
      title: "전문 A/S 서비스",
      description:
        "숙련된 전문가의 신속하고 정확한 서비스로 제품의 최적 상태를 유지합니다.",
      details: [
        "24시간 긴급 출동 서비스",
        "정기적인 무상 점검 서비스",
        "genuine 부품 사용 보장",
      ],
      ctaText: "서비스 예약하기",
    },
    {
      icon: <ShoppingCart />,
      title: "공동구매 서비스",
      description:
        "아파트 단지를 위한 특별 공동구매 혜택으로 더 경제적인 구매가 가능합니다.",
      details: [
        "단지별 맞춤 할인 혜택",
        "무이자 할부 옵션 제공",
        "단체 설치 시 추가 할인",
      ],
      ctaText: "공동구매 신청하기",
    },
    {
      icon: <Phone />,
      title: "고객 상담 센터",
      description:
        "친절한 상담원이 제품 관련 모든 문의사항에 대해 상세히 안내해 드립니다.",
      details: [
        "평일 9AM-6PM 운영",
        "실시간 채팅 상담 가능",
        "전문가와의 1:1 상담 예약",
      ],
      ctaText: "상담 문의하기",
    },
  ];

  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-4xl font-bold text-center mb-4 text-gray-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          고객 서비스
        </motion.h2>
        <motion.p
          className="text-center text-gray-600 mb-12 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          다이손은 고객 만족을 최우선으로 생각합니다. 제품 구매부터 사후
          관리까지, 모든 단계에서 최상의 서비스를 제공하기 위해 노력하고
          있습니다.
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <ServiceCard {...service} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
