import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Services from '../components/Services';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>다이손 - 친환경 음식물 분쇄처리기 | 홈</title>
        <meta name="description" content="다이손의 친환경 음식물 분쇄처리기로 더 나은 주방 생활을 경험하세요. 막힘없는 분쇄력, 저렴한 유지비용, 편리하고 안전한 사용" />
        <link rel="canonical" href="https://www.daison.com" />
      </Helmet>
      <Hero />
      <Features />
      <Services />
    </>
  );
};

export default HomePage;
