import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

const images = [
  "/images/daison_2.jpg",
  "/images/daison_3.jpg",
  "/images/daison_4.jpg",
];

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section className="relative h-screen overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.img
          key={currentImage}
          src={images[currentImage]}
          alt="Hero Banner"
          className="absolute inset-0 w-full h-full object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
      </AnimatePresence>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <motion.div
          className="text-center text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <h1 className="text-5xl mb-4">행복한 가정, 청결한 주방 🏠</h1>
          <p className="text-xl mb-8">
            다이손의 친환경 음식물 분쇄처리기로 더 나은 생활을 시작하세요 ♻️
          </p>
          <motion.button
            className="bg-emerald-600 text-white px-8 py-3 rounded-full hover:bg-emerald-700 transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to={`/제품소개`}
              className="block py-2 hover:text-emerald-500 transition duration-300"
            >
              제품 알아보기 👀
            </Link>
          </motion.button>
        </motion.div>
      </div>
      <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
        onClick={() =>
          setCurrentImage(
            (prevImage) => (prevImage - 1 + images.length) % images.length
          )
        }
      >
        <ChevronLeft size={48} />
      </button>
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white"
        onClick={() =>
          setCurrentImage((prevImage) => (prevImage + 1) % images.length)
        }
      >
        <ChevronRight size={48} />
      </button>
    </section>
  );
};

export default Hero;
