import React, { useState } from "react";
import { motion } from "framer-motion";
import { Disclosure } from "@headlessui/react";
import {
  ChevronDown,
  Phone,
  Mail,
  Clock,
  Send,
  User,
  AtSign,
  MessageSquare,
} from "lucide-react";

const SupportPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email, message });
  };

  const faqs = [
    {
      question: "음식물분쇄기(Disposer)란 무엇인가요?",
      answer:
        "음식물분쇄기란 부엌에서 음식물 찌꺼기나 생선뼈 등을 분쇄하여 하수도로 흘려보내는 장치입니다. 국내에서는 주방용오물분쇄기로 알려져 있으며, 일반 건조식 음식물처리기와 달리 싱크대 바로 밑에 부착하여 음식물쓰레기를 분쇄 후 물과 함께 처리합니다.",
    },
    {
      question: "음식물 분쇄기는 불법인가요?",
      answer:
        "음식물 분쇄기의 사용은 지역 및 규정에 따라 다릅니다. 최근 일부 지역에서 특정 조건 하에 사용이 허용되고 있으나, 설치 전 반드시 해당 지역의 법규를 확인해야 합니다.",
    },
    {
      question: "세금혜택 구매가 가능한가요?",
      answer:
        "특정 에너지 효율적인 모델의 경우 세금 혜택이 적용될 수 있습니다. 구체적인 혜택은 현재 정부 정책과 제품 모델에 따라 다르므로, 구매 전 전문가와 상담하시는 것이 좋습니다.",
    },
    {
      question: "공동구매 방법이 있나요?",
      answer:
        "네, 공동구매는 가능합니다. 주기적으로 공동구매 이벤트를 진행하고 있으며, 홈페이지나 고객센터를 통해 일정을 확인하실 수 있습니다. 아파트 단지나 지역 커뮤니티를 통한 공동구매도 가능합니다.",
    },
    {
      question: "음식물분쇄처리기 사용 시 하수구 막힘 문제는 없나요?",
      answer:
        "올바르게 사용할 경우 하수구 막힘 문제는 거의 발생하지 않습니다. 우리 제품은 효율적인 분쇄 시스템을 갖추고 있어 대부분의 음식물을 잘게 분쇄하여 하수구 막힘을 방지합니다. 단, 정기적인 유지보수와 올바른 사용법 준수가 중요합니다.",
    },
    {
      question: "A/S는 어떻게 받을 수 있나요?",
      answer:
        "제품 고장 시 홈페이지나 고객센터를 통해 A/S를 신청하실 수 있습니다. 전문 기사가 방문하여 점검 및 수리를 진행합니다. 보증 기간 내의 정상적인 사용 중 발생한 고장은 무상으로 처리됩니다.",
    },
    {
      question: "이사 시 제품을 가져갈 수 있나요?",
      answer:
        "네, 가능합니다. 다만 전문가의 도움을 받아 안전하게 제거하고 재설치하는 것이 좋습니다. 이사 전 고객센터에 문의하시면 자세한 안내를 받으실 수 있습니다.",
    },
    {
      question: "렌탈 서비스는 제공되나요?",
      answer:
        "네, 렌탈 서비스를 제공하고 있습니다. 월 단위로 이용 가능하며, 정기적인 유지보수가 포함되어 있어 편리하게 사용하실 수 있습니다. 자세한 렌탈 조건은 홈페이지에서 확인하실 수 있습니다.",
    },
  ];

  return (
    <motion.div
      className="container mx-auto px-4 py-12"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">
        고객 지원
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="bg-gradient-to-br from-emerald-500 to-teal-600 p-6 rounded-lg shadow-lg text-white">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Phone className="mr-2" /> 고객센터 연락처
          </h2>
          <p className="flex items-center mb-2">
            <Phone className="mr-2" /> 1234-5678
          </p>
          <p className="flex items-center mb-2">
            <Mail className="mr-2" /> support@daison.com
          </p>
          <p className="flex items-center">
            <Clock className="mr-2" /> 평일 9:00 - 18:00 (주말 및 공휴일 휴무)
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
            <MessageSquare className="mr-2 text-emerald-500" /> 문의하기
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1 flex items-center"
              >
                <User className="mr-1 w-4 h-4" /> 이름
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1 flex items-center"
              >
                <AtSign className="mr-1 w-4 h-4" /> 이메일
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1 flex items-center"
              >
                <MessageSquare className="mr-1 w-4 h-4" /> 문의내용
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
            >
              <Send className="mr-2" /> 문의 보내기
            </button>
          </form>
        </div>
      </div>

      <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">
          자주하는 질문
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div className="bg-white rounded-lg shadow">
                  <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left text-emerald-900 bg-emerald-100 rounded-lg hover:bg-emerald-200 focus:outline-none focus-visible:ring focus-visible:ring-emerald-500 focus-visible:ring-opacity-75">
                    <span>{faq.question}</span>
                    <ChevronDown
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-emerald-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    {faq.answer}
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default SupportPage;
