import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollY } = useViewportScroll();
  const headerOpacity = useTransform(scrollY, [0, 100], [0.6, 1]);
  const headerVisibility = useTransform(
    scrollY,
    [0, 100],
    ["visible", "visible"]
  );
  const backgroundColor = useTransform(
    scrollY,
    [0, 100],
    ["rgba(255, 255, 255, 0.3)", "rgba(255, 255, 255, 0.8)"]
  );
  const textColor = useTransform(
    scrollY,
    [0, 100],
    ["rgb(0, 0, 0)", "rgb(0, 0, 0)"]
  );

  useEffect(() => {
    return scrollY.onChange(() => {});
  }, [scrollY]);

  return (
    <motion.header
      className="fixed top-0 left-0 right-0 z-50 backdrop-blur-sm shadow-sm"
      style={{
        backgroundColor,
        opacity: headerOpacity,
        visibility: headerVisibility,
      }}
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link
          to="/"
          className="text-2xl font-bold"
          style={{ fontFamily: "JalnanGothic" }}
        >
          <motion.span style={{ color: "#10B981" }}>DAISON</motion.span>
        </Link>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            {["제품소개", "고객지원", "회사소개"].map((item, index) => (
              <li key={index}>
                <Link to={`/${item.toLowerCase()}`}>
                  <motion.span
                    className="hover:text-emerald-500 transition duration-300"
                    style={{ color: textColor }}
                  >
                    {item}
                  </motion.span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <motion.button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden"
          style={{ color: textColor }}
        >
          {isOpen ? <X /> : <Menu />}
        </motion.button>
      </div>
      {isOpen && (
        <motion.div
          className="md:hidden bg-white bg-opacity-80 backdrop-blur-sm"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <ul className="px-4 py-2">
            {["제품소개", "고객지원", "회사소개"].map((item, index) => (
              <li key={index}>
                <Link
                  to={`/${item.toLowerCase()}`}
                  className="block py-2 hover:text-emerald-500 transition duration-300"
                  onClick={() => setIsOpen(false)}
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </motion.header>
  );
};

export default Header;