import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import SupportPage from "./pages/SupportPage";
import "./styles/tailwind.css";
import "./styles/fonts.css";

const App = () => {
  return (
    <Router>
      <div className=" bg-gray-50">
        <Header />
        <div className="pt-16">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/제품소개" element={<ProductPage />} />
            <Route path="/고객지원" element={<SupportPage />} />
            <Route path="/회사소개" element={<SupportPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
